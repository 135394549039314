let wechat = {
  data() {
    return {
      usernameRsa: true,
      weChatToken: '',
      TenantConfig: {}
    }
  },
  methods: {
    oauthFail: function() {
      this.getPublicKey().then(res => {
        if (res.result == false) {
          return
        }
        localStorage.setItem('loginType', 'weChatQiYe')
        this.loginData.userName = 'visitor'
        this.loginData.userPassword = 'Visitor13579'
        this.login({ usernameRsa: this.usernameRsa })
      })
    },
    loginByRoleId: function(config) {
      this.getPublicKey().then(res => {
        if (res.result == false) {
          return
        }
        localStorage.setItem('loginType', 'weChatQiYe')
        let roleIds = config['roleIds']
        this.loginData.userName = roleIds + 'visitor'
        this.loginData.userPassword = 'Visitor13579'
        this.login({ usernameRsa: this.usernameRsa })
      })
    },
    getUrlParameter: function() {
      this.$clearLocal()
      this.loadingStatus = true
      let url = window.location.href
      let urls = url.split('#')
      if (urls.length == 0) {
        console.log(urls)
        return
      }
      let first = urls[0]
      let firsts = first.split('?')
      if (firsts.length <= 1) {
        console.log(firsts)
        return
      }
      let paramters = firsts[1]
      let list = paramters.split('&')
      let paramterInfo = {}
      for (let i = 0; i < list.length; i++) {
        let str = list[i]
        let strs = str.split('=')
        let key = strs[0]
        let value = strs[1]
        paramterInfo[key] = value
      }
      if (this.$valueIsExist(paramterInfo, 'code') == false && this.$valueIsExist(paramterInfo, 'auth_code') == false) {
        return
      }
      if (this.$valueIsExist(paramterInfo, 'state') == false) {
        return
      }
      let state = paramterInfo['state']
      let states = state.split(',')
      if (states.length <= 1) {
        return
      }
      let tenantId = states[0]
      let roleId = states[1]

      if (states.length >= 3) {
        let alertId = states[2]
        this.$store.commit('setAlertInfo', { id: alertId })
      }

      let code = ''
      if (this.$valueIsExist(paramterInfo, 'code')) {
        code = paramterInfo['code']
      } else if (this.$valueIsExist(paramterInfo, 'auth_code')) {
        code = paramterInfo['auth_code']
      }

      let config = {
        tenantId: tenantId,
        roleIds: roleId,
        code: code
      }
      // 获取机构设置
      this.getConfig(config).then(result => {
        if (result.result == false) {
          this.loginByRoleId(config)
          return
        }
        let data = result.data
        if (this.$valueIsExist(data, 'thirdInfo') && data['thirdInfo'] != '') {
          this.getTokenByTheThird(data, config)
        } else {
          this.getTenantToken(data, config)
        }
      })
    },
    judgeUrl: function(url) {
      if (url.indexOf('https') >= 0) {
        return url;
      }
      if (url.indexOf('http') < 0) {
        return url;
      }
      let match = '^http://[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+:[0-9]+'
      let matchTransMatrix = new RegExp(match, 'gmi')
      let rep = url.match(matchTransMatrix)
      if (rep) {
        return url
      }
      return url.replace(/http/g, 'https')
    },
    getConfig: function(config) {
      let updateIpConfig = (res) => {
        let result = {
          result: false
        }
        let dataStr = res.data
        try {

          let data = JSON.parse(dataStr)
          if (this.$valueIsExist(data, 'rfidUrl') && data['rfidUrl'] != '') {
            let rfidUrl = data['rfidUrl']
            this.rfidUrl = rfidUrl
            window.ipConfig.VUE_APP_RFIDURL = this.judgeUrl(this.rfidUrl)
            localStorage.setItem('ipConfig', JSON.stringify(window.ipConfig))
          }
          if (this.$valueIsExist(data, 'assetsUrl') && this.$valueIsExist(data, 'baseUrl') && data['baseUrl'] != '' && data['assetsUrl'] != '') {
            let baseUrl = data['baseUrl']
            let assetsUrl = data['assetsUrl']
            this.baseUrl = baseUrl
            this.assetsUrl = assetsUrl
            window.ipConfig.VUE_APP_ASSETURL = this.judgeUrl(this.assetsUrl)
            window.ipConfig.VUE_APP_BASEURL = this.judgeUrl(this.baseUrl)
            localStorage.setItem('ipConfig', JSON.stringify(window.ipConfig))
          }
          let showUserInfo = '1'

          if (this.$valueIsExist(data, 'showUserInfo') && data['showUserInfo'] != '') {
            showUserInfo = data['showUserInfo']
          }

          localStorage.setItem('showUserInfo', showUserInfo)

          let viewType = 'sso' // 使用模块

          if (this.$valueIsExist(data, 'viewType') && data['viewType'] != '') {
            viewType = data['viewType']
          }

          localStorage.setItem('viewType', viewType)

          let WEB_TYPE = 'auto'
          if (this.$valueIsExist(process, 'env') && this.$valueIsExist(process.env, 'VUE_APP_WEB_TYPE')) {
            WEB_TYPE = process.env.VUE_APP_WEB_TYPE
          }

          if (WEB_TYPE == 'auto') {
            let weChatType = 'web' // 使用方式 小程序或者网页

            if (this.$valueIsExist(data, 'weChatType') && data['weChatType'] != '') {
              weChatType = data['weChatType']
            }

            localStorage.setItem('weChatType', weChatType)
            if (weChatType == 'weChat') {
              this.$bus.emit('weChatType', { weChatType: weChatType })
            }
          } else {
            localStorage.setItem('weChatType', WEB_TYPE)
          }

          let breatheIds = ''
          if (this.$valueIsExist(data, 'breatheIds') && data['breatheIds'] != '') {
            breatheIds = data['breatheIds']
          }

          localStorage.setItem('breatheIds', breatheIds)

          let monitorIds = ''
          if (this.$valueIsExist(data, 'monitorIds') && data['monitorIds'] != '') {
            monitorIds = data['monitorIds']
          }

          localStorage.setItem('monitorIds', monitorIds)

          let narcotismIds = ''
          if (this.$valueIsExist(data, 'narcotismIds') && data['narcotismIds'] != '') {
            narcotismIds = data['narcotismIds']
          }

          localStorage.setItem('narcotismIds', narcotismIds)

          let usernameRsa = '1'
          if (this.$valueIsExist(data, 'usernameRsa')) {
            usernameRsa = data['usernameRsa']
          }
          this.usernameRsa = usernameRsa == '1' ? true : false

          let accountTenantId = config['tenantId']
          if (this.$valueIsExist(data, 'accountTenantId') && data['accountTenantId'] != '') {
            accountTenantId = data['accountTenantId']
          }

          config['accountTenantId'] = accountTenantId

          if (this.$valueIsExist(data, 'corpInfo')) {
            let corpInfo = data['corpInfo']
            localStorage.setItem('corpInfo', JSON.stringify(corpInfo))
          }
          console.log('~~~')
          console.log(data)

          let pageInfo = this.$enterPageInfo()
          if (this.$valueIsExist(data, 'page')) {
            pageInfo = data['page']
          }

          let loginMsg = this.loginMsg
          if (this.$valueIsExist(data, 'loginMsg')) {
            loginMsg = data['loginMsg']
          }
          this.loginMsg = loginMsg

          localStorage.setItem('pageInfo', JSON.stringify(pageInfo))
          result.result = true
          result['data'] = data
        } catch (e) {

        }

        return result
      }

      return new Promise(resolve => {
        this.$api.loginApi.getTenantConfig({ key: config['tenantId'] }).then(res => {
          if (this.$valueIsExist(res, 'data') == false) {
            resolve({ result: false })
            return
          }
          resolve(updateIpConfig(res))
        }).catch(e => {
          resolve({ result: false })
        })
      })

    },
    getTokenByTheThird: function(TenantConfig, config) {
      let getUser = (config, TenantConfig) => {
        let third = TenantConfig['thirdInfo']
        if (this.$valueIsExist(third, 'url1') == false || this.$valueIsExist(third, 'param1') == false) {
          this.loginByRoleId(config)
          return
        }

        let url1 = third['url1']
        let param1Str = third['param1']

        try {
          let param1 = JSON.parse(param1Str)
          let dataInfo = { token: this.weChatToken }
          for (const key in param1) {
            if (this.$valueIsExist(config, key) == false) {
              dataInfo[key] = ''
              continue
            }
            dataInfo[key] = config[key]
          }
          this.$api.loginApi.getWechatToken(url1, dataInfo).then(res => {
            if (this.$valueIsExist(res, ['data']) == false) {
              this.loginByRoleId(config)
              return
            }
            let data = res['data']
            if (this.$valueIsExist(data, ['user_id']) == false) {
              this.loginByRoleId(config)
              return
            }

            let user_id = data['user_id']

            let mobile = ''
            if (this.$valueIsExist(data, ['phone'])) {
              mobile = data['phone']
            }

            let avatar = ''
            if (this.$valueIsExist(data, ['avatar'])) {
              avatar = data['avatar']
            }

            let name = ''
            if (this.$valueIsExist(data, ['name'])) {
              name = data['name']
            }

            let company_id = ''
            if (this.$valueIsExist(data, ['company_id'])) {
              company_id = data['company_id']
            }

            if (name == '') {
              name = mobile
            }

            let userData = {
              name: name,
              mobile: mobile,
              avatar: avatar,
              userid: mobile,
              wechatUserId: user_id,
              company_id: company_id
            }
            this.makeNewAccount(userData, config)
          })
        } catch (e) {
          this.loginByRoleId(config)
        }

      }

      let third = TenantConfig['thirdInfo']
      let url = third['url']
      let paramStr = third['param']
      try {
        let param = JSON.parse(paramStr)
        this.$api.loginApi.getWechatToken(url, param).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            this.loginByRoleId(config)
            return
          }
          let data = res['data']
          if (this.$valueIsExist(data, ['token']) == false) {
            this.loginByRoleId(config)
            return
          }
          this.weChatToken = data['token']
          getUser(config, TenantConfig)
        })
      } catch (e) {
        this.loginByRoleId(config)
      }
    },
    getNewUrl: function(url, info) {
      let keys = Object.keys(info)
      let newUrl = url
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        if (i == 0) {
          newUrl = newUrl + '?'
        }
        newUrl = newUrl + key + '=' + info[key] + (i != keys.length - 1 ? '&' : '')
      }
      return newUrl
    },
    getTenantToken: function(TenantConfig, config) {
      let weChatToken = (TenantConfig, config) => {
        if (this.$valueIsExist(TenantConfig, 'corpInfo') == false && TenantConfig['corpInfo'] != '') {
          this.loginByRoleId(config)
          return
        }
        let corpInfo = TenantConfig['corpInfo']
        localStorage.setItem('corpInfo', JSON.stringify(corpInfo))
        let url = this.getNewUrl('https://qyapi.weixin.qq.com/cgi-bin/gettoken', corpInfo)
        this.$api.weChatLogin.getUrl({
          url: url
        }).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            this.loginByRoleId(config)
            return
          }
          let dataStr = res['data']
          try {
            let data = JSON.parse(dataStr)
            if (this.$valueIsExist(data, ['access_token']) == false) {
              this.loginByRoleId(config)
              return
            }
            this.weChatToken = data['access_token']
            this.getUser(config)
          } catch (e) {
            this.loginByRoleId(config)
          }
        })
      }

      console.log('TenantConfig')
      console.log(TenantConfig)
      if (this.$valueIsExist(TenantConfig, 'tokenInfo') == false && TenantConfig['tokenInfo'] != '') {
        weChatToken(TenantConfig, config)
        return
      }
      let tokenInfo = TenantConfig['tokenInfo']
      let url = tokenInfo['url']
      this.$api.loginApi.getWechatToken(url, tokenInfo['param']).then(res => {
        if (this.$valueIsExist(res, ['data']) == false) {
          this.loginByRoleId(config)
          return
        }
        let data = res['data']
        if (this.$valueIsExist(data, ['access_token']) == false) {
          this.loginByRoleId(config)
          return
        }
        this.weChatToken = data['access_token']
        this.getUser(config)
      })
    },
    getUser: function(config) {
      let code = config['code']
      let info = {
        'access_token': this.weChatToken,
        'code': code
      }
      let url = this.getNewUrl('https://qyapi.weixin.qq.com/cgi-bin/user/getuserinfo', info)
      this.$api.weChatLogin.getUrl({
        url: url
      }).then(res => {
        if (this.$valueIsExist(res, ['data']) == false) {
          this.loginByRoleId(config)
          return
        }
        let dataStr = res['data']
        try {
          let data = JSON.parse(dataStr)
          if (this.$valueIsExist(data, ['UserId']) == false) {
            this.loginByRoleId(config)
            return
          }
          let UserId = data['UserId']
          this.getUserDetailInfo(UserId, config)
        } catch (e) {
          this.loginByRoleId(config)
        }
      })
    },
    getUserDetailInfo: function(UserId, config) {
      let info = {
        'access_token': this.weChatToken,
        userid: UserId
      }
      let url = this.getNewUrl('https://qyapi.weixin.qq.com/cgi-bin/user/get', info)
      this.$api.weChatLogin.getUrl({
        url: url
      }).then(res => {
        if (this.$valueIsExist(res, ['data']) == false) {
          this.loginByRoleId(config)
          return
        }
        let dataStr = res['data']
        try {
          let data = JSON.parse(dataStr)
          console.log(data)
          this.makeNewAccount(data, config)
        } catch (e) {
          this.loginByRoleId(config)
        }
      })
    },
    makeNewAccount: function(userData, config) {
      let userid = userData['userid']
      let roleIds = config['roleIds']
      let tenantId = config['accountTenantId']
      let formData = new FormData()
      formData.append('wechatUserId', userid)
      formData.append('roleIds', roleIds)
      formData.append('tenantId', tenantId)
      formData.append('json', JSON.stringify(userData))

      let loginInfo = {
        wechatUserId: userid,
        roleIds: roleIds,
        tenantId: tenantId,
        json: JSON.stringify(userData)
      }

      localStorage.setItem('weChatUserLogin', JSON.stringify(loginInfo))

      this.$api.loginApi.updateUserByWechat(formData).then(res => {
        if (this.$valueIsExist(res, ['data']) == false) {
          return
        }
        localStorage.setItem('weChatUserInfo', JSON.stringify(userData))

        let key = res['data']

        this.loginByKey({
          key: key,
          grant_type: 'key',
          client_secret: 'kcIotServer',
          client_id: 'kangCloudSSO'
        })

      }).catch(e => {

      })

    },
    loginByKey: function(config) {
      this.$api.loginApi.getLoginKey(config).then(res => {
        localStorage.setItem('loginType', 'weChatQiYe')
        this.loadingStatus = false
        this.$message({
          type: 'success',
          message: '登录成功'
        })
        this.$store.commit('setUpUserToken', res.data)
        this.loginAndGetPage()
      }).catch(e => {
        this.loadingStatus = false
        this.$message({
          type: 'warning',
          message: '登录失败'
        })
      })
    },
    // 通过微信信息登录
    weChat: function() {
      this.loadingStatus = true
      if (typeof localStorage['weChatUserLogin'] == 'undefined') {
        return
      }
      let weChatUserLoginStr = localStorage.getItem('weChatUserLogin')
      try {
        let weChatUserLogin = JSON.parse(weChatUserLoginStr)
        let formData = new FormData()
        for (const key in weChatUserLogin) {
          if (key == 'json') {
            formData.append(key, JSON.stringify(weChatUserLogin[key]))
            continue
          }
          formData.append(key, weChatUserLogin[key])
        }

        this.$api.loginApi.updateUserByWechat(formData).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            return
          }
          let key = res['data']

          this.loginByKey({
            key: key,
            grant_type: 'key',
            client_secret: 'kcIotServer',
            client_id: 'kangCloudSSO'
          })

        }).catch(e => {

        })
      } catch (e) {

      }

    }
  }
}

export default wechat
