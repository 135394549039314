<template>
  <div class="vStack"
       :style="{'flex-wrap':flexWrap, 'justify-content': justifyContent,'align-items': alignItems, 'flex-direction':flexDirection}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vStack',
  props: {
    justifyContent: {
      type: String,
      default: 'center'
    },
    alignItems: {
      type: String,
      default: 'center'
    },
    flexDirection: {
      type: String,
      default: 'column'
    },
    flexWrap: {
      type: String,
      default: 'nowrap'
    }
  }
}
</script>

<style lang="scss" scoped>

.vStack {
  display: flex;
}

</style>
