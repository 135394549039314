import { render, staticRenderFns } from "./vStack.vue?vue&type=template&id=7f460304&scoped=true&"
import script from "./vStack.vue?vue&type=script&lang=js&"
export * from "./vStack.vue?vue&type=script&lang=js&"
import style0 from "./vStack.vue?vue&type=style&index=0&id=7f460304&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f460304",
  null
  
)

export default component.exports