<template>
  <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="90%"
  >
  <div class="detailLog">
    <div class="message">
      {{alertMessage}}
    </div>
    <div class="footer">
      <el-button @click="dialogVisible=false">返回</el-button>
      <!--      <el-button type="primary">确定</el-button>-->
      <el-button type="primary"><el-link type="primary" :href="redirect"></el-link>确定</el-button>


    </div>
  </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'showMessage',
  data() {
    return {
      dialogVisible: false,
      alertMessage: "",
      redirect:'',
    }
  },
  methods:{
    showOrHidden: function(show, alertMessage, redirect) {
      this.dialogVisible = show;
      this.alertMessage = alertMessage;
      this.redirect = redirect;
      console.log(redirect)
    },
    redirectTo:function() {
      window.location.href = this.redirect
    }
  }
}
</script>


<style>
.el-message-box {
  width: 80%;
}
</style>

<style lang="scss" scoped>

.detailLog {
  border-top: 1px solid #cccccc;
  width: 100%;
  height: 100px;
  padding-top: 10px;

  .message {
    text-align: center;
    line-height: 30px;
    font-size: 20px;
  }

  .footer {
    width: 100%;
    height: 40px;
    text-align: center;
    margin-top: 20px;
  }
}


</style>
