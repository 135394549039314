<template>
  <div class="login" id="loginView" :style="{ 'background-image': 'url(' + loginImg + ')' }">
    <div v-if="showView" class="loginView" justify-content="flex-start">
      <div class="headTitle">
        你好
      </div>
      <div class="subTitle">
        {{ loginMsg }}
      </div>


      <van-field v-model="loginData.userName" class="inPutInfo" placeholder="请输入用户名"/>

      <van-field v-model="loginData.userPassword" type="password" class="inPutInfo" placeholder="请输入密码"/>

      <h-stack justify-content="space-between" align-items="none">
        <van-field v-model="loginData.userCode" class="inPutInfo inPutInfo-Code" placeholder="请输入验证码"/>
        <div class="code_img_box">
          <img :src="codeImg" alt="">
        </div>
      </h-stack>

      <div class="remeberPassView">
        <van-checkbox class="remeberPass" v-model="remeberCode" icon-size="12px">记住密码</van-checkbox>
        <div style="clear: both"></div>
      </div>


      <button class="loginButton" @click="login({usernameRsa:true})">
        登录
      </button>

      <div v-if="1<0" class="editorIPHead">服务器</div>
      <ul v-if="1<0" class="editorIPContent">
        <li @click="pushInPut('all', '单点', baseUrl, 'baseUrl')">
          <cell_title_detail head-title="单点" :content-title="baseUrl"></cell_title_detail>
        </li>
        <li @click="pushInPut('all', '资产', assetsUrl, 'assetsUrl')">
          <cell_title_detail head-title="资产" :content-title="assetsUrl"></cell_title_detail>
        </li>
        <li @click="pushInPut('all', 'RFID', rfidUrl, 'rfidUrl')">
          <cell_title_detail head-title="RFID" :content-title="rfidUrl"></cell_title_detail>
        </li>
      </ul>
      <button v-if="1<0" class="loginButton" @click="saveData">
        保存
      </button>
    </div>


    <!--    <v-stack v-if="showView" class="loginView" justify-content="flex-start">-->
    <!--      <div class="headTitle">-->
    <!--        你好-->
    <!--      </div>-->
    <!--      <div style="height: 10px"></div>-->
    <!--      <div class="subTitle">-->
    <!--        欢迎登录慈安通系统-->
    <!--      </div>-->
    <!--      <div style="height: 20px"></div>-->
    <!--      <input v-model="loginData.userName" type="text" class="inPutInfo" placeholder="请输入用户名">-->
    <!--      <div style="height: 10px"></div>-->
    <!--      <input v-model="loginData.userPassword" type="password" class="inPutInfo" placeholder="请输入密码">-->
    <!--      <div style="height: 10px"></div>-->
    <!--      <h-stack style="width: calc(100% - 20px);" justify-content="space-between">-->
    <!--        <input v-model="loginData.userCode" type="text" class="inPutInfo inPutInfo-Code" placeholder="请输入验证码">-->
    <!--        <div class="code_img_box">-->
    <!--          <img :src="codeImg" alt="">-->
    <!--        </div>-->
    <!--      </h-stack>-->

    <!--      <div style="height: 20px"></div>-->
    <!--      <button class="loginButton" @click="login({usernameRsa:true})">-->
    <!--        登录-->
    <!--      </button>-->

    <!--      <div v-if="1<0" class="editorIPHead">服务器</div>-->
    <!--      <ul v-if="1<0" class="editorIPContent">-->
    <!--        <li @click="pushInPut('all', '单点', baseUrl, 'baseUrl')">-->
    <!--          <cell_title_detail head-title="单点" :content-title="baseUrl"></cell_title_detail>-->
    <!--        </li>-->
    <!--        <li @click="pushInPut('all', '资产', assetsUrl, 'assetsUrl')">-->
    <!--          <cell_title_detail head-title="资产" :content-title="assetsUrl"></cell_title_detail>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--      <button v-if="1<0" class="loginButton" @click="saveData">-->
    <!--        保存-->
    <!--      </button>-->
    <!--    </v-stack>-->
    <in-put @updateInfo="updateInfo" v-if="showEditor" :in-put-info="inPutInfo"></in-put>
  </div>
</template>

<script>
import VStack from '@/components/vStack/vStack'
import HStack from '@/components/hStack/hStack'
import { Loading } from 'element-ui'
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import InPut from '@/components/inPut/inPut'
import wechat from '@/views/login/wechat'
import ShowMessage from '@/views/login/showMessage'
import { rsaDecrypt, rsaEncrypt } from '@utils/rsaEncrypt'

let loginImg = require('../../../static/iconImage/loginBGImg.png')
let priKey = '-----BEGIN RSA PRIVATE KEY-----MIICXQIBAAKBgQDvupVIIoSGBwdLXqP/ox0YYr1pj7ZmadC7i0mujqzIjpBh/NCJmZWtb4rmZyN18PPcctxIbyndJQ//BrUnFc4v0F4fjciHBuwSBAtaMBjoyj2CBiijHK6H96+cHv+AiudD0Vf3Ij0T7BaLZJZ/Ss3M25mRuT5cYN09M8Bt34Dv2wIDAQABAoGAVJQNqx+Shf7g0fSYA882qq3biezMO6HFpQVlf5KS30d9JTUfFgz7w+8AoH1vA2N5hiN4GI4vxPgYhq+FJj8JOSKAcmaGYnPKUd41yI/07tHMxNuXngJW0AyjhFFloEwp620VZGMzdPqkUMG5JvIViYoXc5yb1bE55l0TOUHUK/ECQQD9BYx4KqlNs9VKdrBla79iqjlgAff8nK542g/pIeKTcin/ARQRxVue/ABHyNnaJY+Ji7fpRACg3u2ECklur1DlAkEA8oz6X2l2xt+lK2bXCJhQ8dI68DGAQZBIwAHNQfTkppZXuTg/EErPo6XgXT3cletQ6+rvF3Dd2lk8loRQ5JzxvwJAdUIxCy+aLqx82HmQ3i/FDlCdLmU7LBLguJk2bnCJtJNf6xHw3xt7jn5zEtF+RJ7Lmo7puG0PbX5izKKHzYfqEQJBALTZGuHDQBW+sWewEUtOTqRP7TQkpI2+KBBKB6JTF52CYbwvzQ23yiQpzSWYt31s7HRLQqRGupRQjxVnaO1ce/8CQQCy65L/c+kqTF3zqrnoaKlScz6D877fsR1MQO3OJdCPkJdKTaGVrtKMazBBp0CN9Z98SJxvqIFizpesQnb+Daq2-----END RSA PRIVATE KEY-----'
let pubKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDvupVIIoSGBwdLXqP/ox0YYr1pj7ZmadC7i0mujqzIjpBh/NCJmZWtb4rmZyN18PPcctxIbyndJQ//BrUnFc4v0F4fjciHBuwSBAtaMBjoyj2CBiijHK6H96+cHv+AiudD0Vf3Ij0T7BaLZJZ/Ss3M25mRuT5cYN09M8Bt34Dv2wIDAQAB-----END PUBLIC KEY-----'

export default {
  name: 'login',
  components: { ShowMessage, InPut, Cell_title_detail, HStack, VStack },
  mixins: [wechat],
  data() {
    return {
      remeberCode: true,
      loginMsg: '欢迎登录慈安通系统',
      loginImg: loginImg,
      showView: false,
      showEditor: false,
      inPutInfo: {},
      baseUrl: window.ipConfig.VUE_APP_BASEURL,
      assetsUrl: window.ipConfig.VUE_APP_ASSETURL,
      rfidUrl:window.ipConfig.VUE_APP_RFIDURL,
      loadingView: null,
      loadingStatus: false,
      loginData: {
        // userName: 'admin',
        // userPassword: 'Admin13579',
        // userCode: 'LSQQ'
        userName: '',
        userPassword: '',
        userCode: 'LSQQ'
      },
      rules: [{
        msg: '请输入准确的用户名',
        match: '.',
        key: 'userName'
      }, {
        msg: '请输入准确的密码,至少6位',
        match: '^[0-9a-zA-Z]{6}',
        key: 'userPassword'
      }, {
        msg: '请输入准确的验证码',
        match: '^[0-9a-zA-Z]{4}$',
        key: 'userCode'
      }],
      codeImg: '',
      uniqueCode: '',
      publicKey: ''
    }
  },
  mounted() {
    localStorage.setItem('href', window.location.href);
    if (this.$valueIsExist(localStorage, 'remeberCode')) {
      let remeberCode = localStorage['remeberCode']
      try {
        let dataInfo = JSON.parse(remeberCode)
        if (this.$valueIsExist(dataInfo, 'userName') && this.$valueIsExist(dataInfo, 'passWord')) {
          let u = dataInfo['userName']
          this.loginData.userName = this.$rsaDecrypt(priKey, u)
          let p = dataInfo['passWord']
          this.loginData.userPassword = this.$rsaDecrypt(priKey, p)
        }
      } catch (e) {

      }
    }

    console.log('version = 0.0.101')
    console.log('width = ' + window.screen.width)
    console.log('height = ' + window.screen.height)
    let enter = 'mainEnter'
    if (this.$valueIsExist(localStorage, 'enter')) {
      enter = localStorage['enter']
    }

    let getEnv = (callBack) => {
      let res = this.$store.getters.isWXEnV
      if (res.result == false) {
        this.WXEnv((envRes) => {
          this.$store.commit('setEnv', envRes)
          callBack()
        })
      } else {
        callBack()
      }
    }

    this.$nextTick(() => {

      // if (this.loginType() != 'weChatQiYe') {
      //   let token = this.$store.getters.userToken
      //   if (token != '') {
      //     getEnv(() => {
      //
      //       this.$router.replace(enter)
      //     })
      //     return
      //   }
      // }

      getEnv(() => {
        this.getQuery()
      })

    })
  },
  beforeRouteLeave(to, from, next) {
    let token = this.$store.getters.userToken
    next(token != '' ? true : false)
  },
  watch: {
    'loginData.userName': {
      handler(value) {
      },
      immediate: true,
      deep: true
    },

    loadingStatus: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    loginType: function() {
      if (typeof localStorage['loginType'] == 'undefined') {
        return ''
      }
      let loginType = localStorage.getItem('loginType')
      return loginType
    },
    // 使用账号登录
    account: function(config) {
      this.showView = true
      localStorage.setItem('loginType', 'account')
      // 获取机构设置
      this.loadingStatus = true
      this.getConfig(config).then(res => {
        this.getCodeImg()
        this.getPublicKey()
        this.loadingStatus = false
      })
    },

    getQuery: function(callBack) {
      this.$clearLocal()
      let query = this.$route.query
      if (typeof query['type'] == 'undefined' || query['type'] == null) {
        this.getUrlParameter()
        return
      }

      let type = query['type']
      if (type == 'weChat') {
        this.weChat()
      } else if (type == 'account') {
        this.account(query)
      } else if (type == 'third') {
        this.loginByThird(query)
      } else if (type == 'app') {
        this.loginByApp(query)
      }
    },
    loginByApp:function(query) {
      localStorage.setItem('weChatType', 'app');
      window.ipConfig.VUE_APP_ASSETURL = this.judgeUrl(this.$getDataValue(query, 'assetsUrl', ''));
      window.ipConfig.VUE_APP_BASEURL = this.judgeUrl(this.$getDataValue(query, 'baseUrl', ''));
      window.ipConfig.VUE_APP_RFIDURL = this.judgeUrl(this.$getDataValue(query, 'rfidUrl', ''));
      localStorage.setItem('ipConfig', JSON.stringify(window.ipConfig))
      this.showView = true;
      this.getCodeImg()
      this.getPublicKey()
      localStorage.setItem('corpInfo', '{}');
      this.loginByThird(query);
    },
    loginByThird: function(query) {
      if (typeof query['tenantId'] == 'undefined' || query['tenantId'] == null) {
        return
      }
      console.log('loginByThird')
      let redirect = (res) => {
        if (this.$valueIsExist(res, 'data') == false) {
          console.log('---- redirect')
          console.log(res)
          return
        }
        let dataStr = res.data
        try {
          let data = JSON.parse(dataStr)
          if (this.$valueIsExist(data, 'redirect') == false) {
            console.log('---- redirect - url')
            console.log(res)
            return
          }
          let redirect = data['redirect']
          window.location.href = redirect
        } catch (e) {
          console.log('---- redirect - error')
          console.log(e)
        }
      }

      let tenantId = query['tenantId']
      this.$api.loginApi.getTenantConfig({ key: tenantId }).then(res => {
        redirect(res)
      }).catch(e => {

      })

    },
    updateInfo: function(dataInfo) {
      for (const key in dataInfo) {
        this[key] = dataInfo[key]
      }
      this.showEditor = false
    },
    pushInPut: function(matchType, headTitle, content, dataKey) {
      this.showEditor = true
      this.inPutInfo = {
        headTitle: headTitle,
        contentTitle: content,
        matchType: matchType,
        dataKey: dataKey,
        inPutKey: 'setDataInfo'
      }
    },
    saveData: function() {
      window.ipConfig.VUE_APP_ASSETURL = this.judgeUrl(this.assetsUrl);
      window.ipConfig.VUE_APP_BASEURL = this.judgeUrl(this.baseUrl);
      window.ipConfig.VUE_APP_RFIDURL = this.judgeUrl(this.rfidUrl);
      localStorage.setItem('ipConfig', JSON.stringify(window.ipConfig))
      this.$message.success('修改成功')
      this.getCodeImg()
      this.getPublicKey()
    },
    startLoading: function() {
      let dom = document.getElementById('loginView')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    getRandomNumber() {
      const dateStr = new Date().getTime().toString()
      const randomStr = parseInt((Math.random() + 1) * Math.pow(10, 7 - 1)).toString()
      return dateStr + randomStr
    },
    getCodeImg: function() {
      this.uniqueCode = this.getRandomNumber()
      this.$api.loginApi.getVrifyCode({ uniqueCode: this.uniqueCode }).then(res => {
        const blob = new Blob([res])
        this.codeImg = window.URL.createObjectURL(blob)
      }).catch((res) => {
        this.codeImg = ''
      })
    },
    getPublicKey: function() {
      return new Promise(resolve => {
        this.$api.loginApi.getPublicKey({}).then(res => {
          this.publicKey = res.data
          resolve({ result: true })
        }).catch((res) => {
          this.publicKey = ''
          resolve({ result: false })
        })
      })
    },
    loginAndGetPage: function() {
      let compareInfo = (info1, info2) => {
        let newInfo = {}
        for (const key in info1) {
          if (this.$valueIsExist(info2, key)) {
            newInfo[key] = Boolean(info2[key])
          } else {
            newInfo[key] = info1[key]
          }
        }
        return newInfo
      }

      let getConfig = (id) => {
        return new Promise(resolve => {
          let pageInfo = this.$enterPageInfo()

          if (this.$valueIsExist(localStorage, ['pageInfo'])) {
            let pageInfoStr = localStorage['pageInfo']
            try {
              pageInfo = JSON.parse(pageInfoStr)
            } catch (e) {

            }
          }

          this.$api.loginApi.getSettingConfig({ key: id }).then(result => {
            let enter = 'mainEnter'
            if (this.$valueIsExist(result, 'data') == false) {
              resolve({ result: true, info: pageInfo, enter: enter })
              return
            }
            try {
              let json = JSON.parse(result.data)
              let options = {}
              if (this.$valueIsExist(json, 'options')) {
                options = json['options']
              }
              if (this.$valueIsExist(json, 'enter')) {
                enter = json['enter']
              }

              resolve({ result: true, info: compareInfo(pageInfo, options), enter: enter })
            } catch (e) {
              resolve({ result: true, info: pageInfo, enter: enter })
            }

          }).catch(e => {
            resolve({ result: true, info: pageInfo, enter: 'mainEnter' })
          })
        })
      }

      let accountRole = (roleIdList) => {
        let promistList = []
        for (let i = 0; i < roleIdList.length; i++) {
          let id = roleIdList[i] + 'role'
          promistList.push(getConfig(id))
        }
        Promise.all(promistList).then(res => {
          let page = {}
          let enterList = []
          for (let i = 0; i < res.length; i++) {
            let r = res[i]
            let info = r.info

            for (const key in info) {
              if (this.$valueIsExist(page, key)) {
                page[key] = info[key] || page[key]
              } else {
                page[key] = info[key]
              }
            }

            let e = r.enter
            if (enterList.indexOf(e) < 0) {
              enterList.push(e)
            }
          }

          console.log(page)

          localStorage.setItem('userPageInfo', JSON.stringify(page))
          let enter = 'mainEnter'
          if (enterList.length == 1) {
            enter = enterList[0]
          }

          let query = {};

          this.$bus.$emit('updateEnter', { enter: enter })
          localStorage.setItem('enter', enter)

          if (enter == 'deviceWorkerHome') {
            enter = 'deviceView1/deviceWorkerHome'
          }

          if (enter == 'deviceRepair') {
            enter = 'deviceBorrow/deviceRepair'
            query = {viewType:'repair'}
          }


          if (page['deviceBorrow']) {
            let access_token = ''
            let userInfostr = localStorage.getItem('userTokenInfo')
            try {
              let userInfo = JSON.parse(userInfostr)
              if (typeof userInfo['access_token'] != 'undefined') {
                access_token = userInfo['access_token']
              }
            } catch (e) {

            }
            query['shouldRemind'] = '1';
            query['token'] = access_token;
            query['assetsUrl'] = window.ipConfig.VUE_APP_ASSETURL;
            query['baseUrl'] = window.ipConfig.VUE_APP_BASEURL;
            query['rfidUrl'] = window.ipConfig.VUE_APP_RFIDURL;
            query['webUrl'] = this.getWebUrl();
          }

          if (this.loginType() == 'weChatQiYe') {
            this.$push(enter, {})
          } else {
            if (typeof query['shouldRemind'] != 'undefined' && query['shouldRemind'] == '1' && typeof localStorage['weChatType'] != 'undefined' && localStorage['weChatType'] != null && localStorage['weChatType'] != '' && localStorage['weChatType'] != 'weChat') {
              /*
              * shouldRemind为需要提醒
              * weChatType 不为微信小程序时开启设备归还提醒
              */
              this.$bus.emit('shouldRemind',{});
            }
            this.$replace(`/${enter}`, query)
            this.$bus.emit('showNavigationStatus',{});
          }
        })

      }

      let accountInfo = () => {
        return new Promise(resolve => {
          this.$api.userInfo.getUserInfo({}).then(res => {
            let data = res.data
            let roleIdList = []
            if (this.loginType() == 'weChatQiYe' && typeof localStorage['weChatUserInfo'] != 'undefined') {
              let weChatUserInfoStr = localStorage.getItem('weChatUserInfo')
              try {
                let weChatUserInfo = JSON.parse(weChatUserInfoStr)
                data['photo'] = weChatUserInfo['avatar']
              } catch (e) {

              }
            }

            if (this.$valueIsExist(data, 'roleVOList')) {
              let roleVOList = data['roleVOList']
              for (let i = 0; i < roleVOList.length; i++) {
                let role = roleVOList[i]
                if (this.$valueIsExist(role, 'id') == false) {
                  roleIdList.push(1)
                  continue;
                }
                roleIdList.push(role.id)
              }
            }

            if (this.$valueIsExist(data, 'roleList') && roleIdList.length == 0) {
              let roleVOList = data['roleList']
              for (let i = 0; i < roleVOList.length; i++) {
                let role = roleVOList[i]
                if (this.$valueIsExist(role, 'id') == false) {
                  roleIdList.push(1)
                  continue;
                }
                roleIdList.push(role.id)
              }
            }




            this.$store.commit('setUpUserInfo', data)
            resolve({ result: true, roleIdList: roleIdList })
          }).catch((e) => {
            resolve({ result: false, roleIdList: [] })
          })
        })
      }

      accountInfo().then(res => {
        if (res.result == false) {
          this.loadingStatus = false
          this.$message({
            type: 'warning',
            message: '登录失败'
          })
          return
        }

        accountRole(res.roleIdList)

      })
    },

    login: function(config = { usernameRsa: false }) {
      console.log('----- login')
      this.validate(this.rules, this.loginData).then(res => {
        if (this.remeberCode) {
          localStorage.setItem('remeberCode', JSON.stringify({
            userName: this.$rsaEncrypt(pubKey, this.loginData.userName),
            passWord: this.$rsaEncrypt(pubKey, this.loginData.userPassword)
          }))
        } else {
          delete localStorage['remeberCode']
        }

        this.loadingStatus = true
        let password = this.$rsaEncrypt(this.publicKey, this.loginData.userPassword)
        let username = this.loginData.userName
        let usernameRsa = false
        if (typeof config != 'undefined' && typeof config['usernameRsa'] != 'undefined') {
          usernameRsa = config['usernameRsa']
        }
        if (usernameRsa) {
          username = this.$rsaEncrypt(this.publicKey, this.loginData.userName)
        }
        this.$api.loginApi.login({
          username: username,
          password: password,
          code: this.loginData.userCode,
          grant_type: 'password',
          client_id: 'kangCloudSSO',
          client_secret: 'kcIotServer',
          uniqueCode: this.uniqueCode
        }).then(res => {
          this.loadingStatus = false
          this.$message({
            type: 'success',
            message: '登录成功'
          })
          this.$store.commit('setUpUserToken', res.data)
          this.$store.commit('setOrgInfo', [])
          this.loginAndGetPage()

        }).catch(error => {
          this.loadingStatus = false
          this.$message({
            type: 'warning',
            message: '登录失败'
          })
        })
      }).catch(res => {
        this.$message({
          type: 'warning',
          message: '登录失败'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.login {
  width: 100%;
  height: 100%;
  left: 0px;
  position: absolute;
  background-color: #eeeeee;
  background-size: 100% 100%;
  background-repeat: no-repeat;


  .loginView {
    width: 100%;
    height: 100%;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    padding: 130px 29px 0px 29px;

    .headTitle {
      width: 100%;
      text-align: left;
      flex-shrink: 0;
      font-size: 28px;
      font-weight: bold;
      color: white;
      line-height: 42px;
      padding: 0px 0px 0px 0px;
    }

    .subTitle {
      width: 100%;
      font-size: 14px;
      color: white;
      text-align: left;
      line-height: 14px;
      padding-left: 20px;
      flex-shrink: 0;
      padding: 11px 0px 30px 0px;
    }

    .inPutInfo {
      width: 100%;
      //height: 32px;
      background-color: white;
      border: transparent;
      border-radius: 20px;
      padding-left: 20px;
      flex-shrink: 0;
      font-size: 14px;
      margin-bottom: 18px;
      line-height: 32px;
    }

    .inPutInfo:focus {
      outline: none;
      flex-shrink: 0;
    }

    .inPutInfo-Code {
      width: calc(100% - 80px);
      flex-shrink: 0;
      float: left;
    }

    .remeberPassView {
      width: 100%;

      .remeberPass {
        float: right;
      }

    }

    .code_img_box {
      float: right;
      display: inline-block;
      height: 32px;
      width: 64px;
      cursor: pointer;
      flex-shrink: 0;

      img {
        height: 100%;
      }
    }

    .loginButton {
      margin-top: 27px;
      width: 100%;
      height: 32px;
      border-radius: 20px;
      text-align: center;
      line-height: 32px;
      color: $color_primary;
      background-color: white;
      border: none;
      font-size: 14px;
      flex-shrink: 0;
      font-weight: bold;
    }

    .editorIPHead {
      margin-top: 10px;
      width: calc(100% - 20px);
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      flex-shrink: 0;
    }

    .editorIPContent {
      background-color: white;
      margin-top: 10px;
      width: calc(100% - 20px);
      padding: 0;
      list-style-type: none;
      border-radius: 5px;
      flex-shrink: 0;
    }
  }
}

::v-deep .loginView .van-cell {
  padding: 0px;
}

::v-deep .van-checkbox__label {
  font-size: 12px;
}

</style>
